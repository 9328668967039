const zh = { 
    '登录后免费畅享高清视频': '登录后免费畅享高清视频',
    '精彩视频观看': '精彩视频观看',
    '创作视频收益': '创作视频收益',
    '请输入手机号': '请输入手机号',
    '请输入密码': '请输入密码',
    '忘记密码': '忘记密码',
    '同意用户协议和隐私政策': '同意用户协议和隐私政策',
    '同意': '同意',
    '用户协议': '用户协议',
    '和': '和',
    '隐私政策': '隐私政策',
    '登录': '登录',
    '注册': '注册',
    '登陆成功': '登陆成功',
    '请确认密码': '请确认密码',
    '请输入邀请码': '请输入邀请码',
    '注册成功': '注册成功',
    '修改成功': '修改成功',
    '请先填写手机号': '请先填写手机号',
    '后重新发送': '后重新发送',
    '重新发送': '重新发送',
    '发送验证码': '发送验证码',
    '修改': '修改',
    '请输入短信验证码': '请输入短信验证码',
    '添加个人信息': '添加个人信息',
    '购买创作会员': '购买创作会员',
    '购买剪辑会员': '购买剪辑会员',
    '任务记录': '任务记录',
    '我的钱包': '我的钱包',
    '我的卡包': '我的卡包',
    '充值': '充值',
    '提现': '提现',
    '修改信息': '修改信息',
    '创作者联盟': '创作者联盟',
    '关于我们': '关于我们',
    '联系客服': '联系客服',
    '退出登录': '退出登录',
    '普通用户无法推广，请开通会员后尝试': '普通用户无法推广，请开通会员后尝试',
    '优质创作': '优质创作',
    '点击加载更多': '点击加载更多',
    '没有更多': '没有更多',
    '视频区间': '视频区间',
    '标题不能为空': '标题不能为空',
    '请选择视频分类': '请选择视频分类',
    '请上传需要发布的视频': '请上传需要发布的视频',
    '上传成功，等待后台审核': '上传成功，等待后台审核',
    '选择分类': '选择分类',
    '请填写标题': '请填写标题',
    '发布': '发布',
    '填写标题': '填写标题',
    '创作': '创作',
    '剪辑': '剪辑',
    '首页': '首页',
    '商业合作': '商业合作',
    '我的': '我的',
    '合作代理': '合作代理',
    '个人代理': '个人代理',
    '公司代理': '公司代理',
    '代理合作条件': '代理合作条件',
    '合作方式': '合作方式',
    '代理收益计划': '代理收益计划',
    '代理公司招聘计划': '代理公司招聘计划',
    '代理公司企业文化制度': '代理公司企业文化制度',
    '拒绝': '拒绝',
    '影视公司': '影视公司',
    '代理合作': '代理合作',
    '影视版权': '影视版权',
    '您还不是剪辑会员，请购买会员后再操作': '您还不是剪辑会员，请购买会员后再操作',
    '购买成功': '购买成功',
    '购买': '购买',
    '实名认证': '实名认证',
    '正面': '正面',
    '反面': '反面',
    '提交': '提交',
    '请上传证件正面': '请上传证件正面',
    '请上传证件反面': '请上传证件反面',
    '上传成功，等待审核': '上传成功，等待审核',
    '删除': '删除',
    '添加地址': '添加地址',
    '提示': '提示',
    '确定要删除该银行卡': '确定要删除该银行卡',
    '删除成功': '删除成功',
    '银行': '银行',
    '请输入银行名称': '请输入银行名称',
    '开户行': '开户行',
    '请输入开户行': '请输入开户行',
    '卡号': '卡号',
    '请输入卡号': '请输入卡号',
    '持卡人': '持卡人',
    '请输入持卡人名称': '请输入持卡人名称',
    '保存': '保存',
    '绑定银行卡': '绑定银行卡',
    '绑定成功': '绑定成功',
    '修改银行卡': '修改银行卡',
    '推广': '推广',
    '团队规模': '团队规模',
    '我的收入': '我的收入',
    '推荐码': '推荐码',
    '复制': '复制',
    '邀请链接': '邀请链接',
    '一级分佣': '一级分佣',
    '返佣比例': '返佣比例',
    '二级分佣': '二级分佣',
    '三级分佣': '三级分佣',
    '规则': '规则',
    '只有充值成功成为会员的人才有资格邀请朋友加入': '只有充值成功成为会员的人才有资格邀请朋友加入',
    '并且需要朋友充值激活VIP会员以后才可以获得邀请奖金': '并且需要朋友充值激活VIP会员以后才可以获得邀请奖金',
    '邀请奖金': '邀请奖金',
    '激活立即获得': '激活立即获得',
    '升级没有奖金，只有首次激活的用户才可以获得这个邀请奖金的被动收入': '升级没有奖金，只有首次激活的用户才可以获得这个邀请奖金的被动收入',
    '复制成功': '复制成功',
    '复制失败': '复制失败',
    '修改昵称': '修改昵称',
    '更改登入密码': '更改登入密码',
    '设置资金密码': '设置资金密码',
    'USDT地址': 'USDT地址',
    'U类型': 'U类型',
    '请选择U类型': '请选择U类型',
    '请输入地址': '请输入地址',
    '原密码': '原密码',
    '请输入原密码': '请输入原密码',
    '新密码': '新密码',
    '请输入新密码': '请输入新密码',
    '提款密码': '提款密码',
    '请输入提款密码': '请输入提款密码',
    '用户昵称': '用户昵称',
    '请输入昵称': '请输入昵称',
    '全部': '全部',
    '已提交': '已提交',
    '审核中': '审核中',
    '处理中': '处理中',
    '成功': '成功',
    '充值金额': '充值金额',
    '充值凭证': '充值凭证',
    '请输入充值金额': '请输入充值金额',
    '提交成功，后台审核中': '提交成功，后台审核中',
    '充值记录': '充值记录',
    '提现记录': '提现记录',
    '待审核': '待审核',
    '已通过': '已通过',
    '已取消': '已取消',
    '创作会员': '创作会员',
    '剪辑会员': '剪辑会员',
    '账号资金': '账号资金',
    '账号余额': '账号余额',
    '线下充值': '线下充值',
    '线上充值': '线上充值',
    '佣金': '佣金',
    '升级剪辑': '升级剪辑',
    '没有更多了': '没有更多了',
    '总资产': '总资产',
    '去充值': '去充值',
    '选择银行卡卡号': '选择银行卡卡号',
    '提现金额': '提现金额',
    '提现密码': '提现密码',
    '请输入提现金额': '请输入提现金额',
    '请输入提现密码': '请输入提现密码',
    '请先绑定银行卡': '请先绑定银行卡',
    '请选择银行卡': '请选择银行卡',
    '提现成功': '提现成功',
    '未设置提款密码': '未设置提款密码',
    '创作等级': '创作等级',
    '剪辑等级': '剪辑等级',
    '新手教程': '新手教程',
    '职位晋升': '职位晋升',
    '公司简介': '公司简介',
    '合法执照': '合法执照',
    'App下载': 'App下载',
    '作品集': '作品集',
    '未通过': '未通过',
    '暂无更多作品': '暂无更多作品',
    '确定要删除该作品': '确定要删除该作品',
    '普通用户无访问权限，请开通会员后尝试': '普通用户无访问权限，请开通会员后尝试',
    '姓名': '姓名',
    '请输入姓名': '请输入姓名',
    '性别': '性别',
    '请输入性别': '请输入性别',
    '年龄': '年龄',
    '请输入年龄': '请输入年龄',
    '身份证编码': '身份证编码',
    '请输入身份证编码': '请输入身份证编码',
    '营业执照': '营业执照',
    '确认': '确认',
    '取消': '取消',
    '男': '男',
    '女': '女',
    '请上传营业执照': '请上传营业执照',
    '切换语言': '切换语言',
    '切换成功': '切换成功',
    

}
export default zh;
