import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vant from 'vant'
import "lib-flexible/flexible.js"
import 'vant/lib/index.css';
import tabbar from './components/tabbar.vue'
import i18n from "./locale/index"

const app = createApp(App);
app.component('tabbar', tabbar)
app.use(i18n)
app.use(vant)
app.use(store)
app.use(router)
app.mount('#app')
