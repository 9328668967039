<template>
  <div class="tabbar">
    <div :class="'box'">
        <div class="item" v-for="(item,index) in list" :key="index" @click="change(item)">
            <div :class="current == item.active ? 'active': 'new-active'" v-if="item.title">{{ item.title }}</div>
            <img v-if="item.icon" :src="current == item.active ? item.selectIcon : item.icon" />
        </div>
    </div>
  </div>
</template>

<script>
import {ref} from 'vue'
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n'
export default {
    props: {
        current: {
            type: Number,
            default: 1
        }
    },
    setup() {
        const {push} = useRouter();
        const {t} = useI18n();
        const list = ref([
        {
            icon: '',
            selectIcon: '',
            title: t('首页'),
            path: '/',
            active: 1
        },
        {
            icon: '',
            selectIcon: '',
            title: t('优质创作'),
            path: '/works',
            active: 4
        },
        {
            icon: new URL("@/assets/svg/icon2.svg", import.meta.url).href,
            selectIcon: new URL("@/assets/svg/icon.svg", import.meta.url).href,
            title: '',
            path: '/release',
            active: 2
        },
        {
            icon: '',
            selectIcon: '',
            title: t('商业合作'),
            path: '/business',
            active: 5
        },
        {
            icon: '',
            selectIcon: '',
            title: t('我的'),
            path: '/mine',
            active: 3
        }
        ]);

        const change = (item)=> {
            push(item.path)
        }

        return {
            list,
            change
        }
    }
}
</script>

<style lang="scss" scoped>
.tabbar {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 990;

    .box, .box2, .new-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        padding: 10px 20px;
        color: #666;
        font-weight: 600;
        background-color: #161615;
        border-top: 1px solid rgba(0,0,0,.1);
        border-top: solid 0.2px rgba(255,255,255,0.2);

        img {
            width: 32px;
            height: 32px;
        }

        .active {
            color: #fff;
        }

        .new-active {
            color: #666;
        }
    }

    .new-box {
        background-color: #fff;

        .active {
            color: #666;
        }

        .new-active {
            color: #999;
        }
    }

    .box2 {
        background-color: #000;

        .active {
            color: #666;
        }

        .new-active {
            color: #999;
        }
    }


    .active, .new-active {
        text-align: center;
    }
}
</style>