import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home/HomeView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/LoginView.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register/RegisterView.vue')
  },
  {
    path: '/release',
    name: 'Release',
    component: () => import('../views/Release/ReleaseView.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import('../views/Mine/MIneView.vue')
  },
  {
    path: '/order',
    name: 'Order',
    component: () => import('../views/Mine/Order/OrderView.vue')
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: () => import('../views/Mine/Wallet/WalletView.vue')
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/Mine/Recharge/RechargeView.vue')
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: () => import('../views/Mine/Withdrawal/WithdrawalView.vue')
  },
  // {
  //   path: '/changePassword',
  //   name: 'ChangePassword',
  //   component: () => import('../views/Mine/changePassword/ChangePassword.vue')
  // },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import('../views/Forgot/ForgotView.vue')
  },
  {
    path: '/bank',
    name: 'Bank',
    component: () => import('../views/Mine/Bank/BankView.vue')
  },
  {
    path: '/binding',
    name: 'Binding',
    component: () => import('../views/Mine/Binding/BindingView.vue')
  },
  {
    path: '/fundDetails',
    name: 'FundDetails',
    component: () => import('../views/Mine/Wallet/FundDetailsView.vue')
  },
  {
    path: '/record',
    name: 'Record',
    component: () => import('../views/Mine/Record/RecordView.vue')
  },
  {
    path: '/vip',
    name: 'Vip',
    component: () => import('../views/Mine/Vip/VipView.vue')
  },
  {
    path: '/invite',
    name: 'Invite',
    component: () => import('../views/Mine/Invite/InviteView.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/Mine/About/AboutView.vue')
  },
  // {
  //   path: '/auth',
  //   name: 'Auth',
  //   component: () => import('../views/Mine/Auth/AuthView.vue')
  // },
  {
    path: '/works',
    name: 'Works',
    component: () => import('../views/Works/WorksView.vue')
  },
  {
    path: '/business',
    name: 'Business',
    component: () => import('../views/Business/BusinessView.vue')
  },
  // {
  //   path: '/info',
  //   name: 'Info',
  //   component: () => import('../views/Mine/Info/InfoView.vue')
  // },
  {
    path: '/locale',
    name: 'locale',
    component: () => import('../views/Lang/LangView.vue')
  },
  {
    path: '/buy',
    name: 'Buy',
    component: () => import('../views/Business/Buy.vue')
  },
  {
    path: '/myInfo',
    name: 'MyInfo',
    component: () => import('../views/Mine/MyInfo/MyInfoView.vue')
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('../views/Agent/AgentView.vue')
  },
  {
    path: '/rich',
    name: 'rich',
    component: () => import('../views/Rich/RichView.vue')
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: () => import('../views/Agreement/AgreementView.vue')
  },
  {
    path: '/personalAgent',
    name: 'personalAgent',
    component: () => import('../views/PersonalAgent/PersonalAgent.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
