const en = {    
    '登录后免费畅享高清视频': 'Enjoy HD videos for free after logging in',
    '精彩视频观看': 'Watch Exciting Videos',
    '创作视频收益': 'Video Creation Earnings',
    '请输入手机号': 'Please enter your phone number',
    '请输入密码': 'Please enter your password',
    '忘记密码': 'Forgot Password',
    '同意用户协议和隐私政策': 'Agree to User Agreement and Privacy Policy',
    '同意': 'Agree',
    '用户协议': 'User Agreement',
    '和': 'and',
    '隐私政策': 'Privacy Policy',
    '登录': 'Log In',
    '注册': 'Register',
    '登陆成功': 'Login Successful',
    '请确认密码': 'Please confirm your password',
    '请输入邀请码': 'Please enter the invitation code',
    '注册成功': 'Registration Successful',
    '修改成功': 'Modification Successful',
    '请先填写手机号': 'Please fill in your phone number first',
    '后重新发送': 'Resend later',
    '重新发送': 'Resend',
    '发送验证码': 'Send Verification Code',
    '修改': 'Modify',
    '请输入短信验证码': 'Please enter the SMS verification code',
    '添加个人信息': 'Add Personal Information',
    '购买创作会员': 'Purchase Creator Membership',
    '购买剪辑会员': 'Purchase Editing Membership',
    '任务记录': 'Task Records',
    '我的钱包': 'My Wallet',
    '我的卡包': 'My Card Package',
    '充值': 'Top Up',
    '提现': 'Withdraw',
    '修改信息': 'Modify Information',
    '创作者联盟': 'Creator Alliance',
    '关于我们': 'About Us',
    '联系客服': 'Contact Customer Service',
    '退出登录': 'Log Out',
    '普通用户无法推广，请开通会员后尝试': 'Regular users cannot promote. Please try after becoming a member.',
    '优质创作': 'High-Quality Creations', 
    '点击加载更多': 'Load More',
    '没有更多': 'No More',
    '视频区间': 'Video Interval',
    '标题不能为空': 'Title Cannot Be Empty',
    '请选择视频分类': 'Please Select Video Category',
    '请上传需要发布的视频': 'Please Upload the Video to Be Published',
    '上传成功，等待后台审核': 'Upload Successful, Waiting for Backend Review',
    '选择分类': 'Select Category',
    '请填写标题': 'Please Fill in the Title',
    '发布': 'Publish',
    '填写标题': 'Fill in the Title',
    '创作': 'Creation',
    '剪辑': 'Editing',
    '首页': 'Home',
    '商业合作': 'Business Cooperation',
    '我的': 'My',
    '合作代理': 'Cooperation Agent',
    '个人代理': 'Individual Agent',
    '公司代理': 'Corporate Agent',
    '代理合作条件': 'Agent Cooperation Conditions',
    '合作方式': 'Cooperation Method',
    '代理收益计划': 'Agent Earnings Plan',
    '代理公司招聘计划': 'Agent Company Recruitment Plan',
    '代理公司企业文化制度': 'Agent Company Corporate Culture System',
    '拒绝': 'Reject',
    '影视公司': 'Film and Television Company',
    '代理合作': 'Agent Cooperation',
    '影视版权': 'Film and Television Copyright',
    '您还不是剪辑会员，请购买会员后再操作': 'You are not an editing member yet. Please purchase the membership before proceeding.',
    '购买成功': 'Purchase Successful',
    '购买': 'Purchase',
    '实名认证': 'Real Name Authentication',
    '正面': 'Front',
    '反面': 'Back',
    '提交': 'Submit',
    '请上传证件正面': 'Please Upload ID Card Front',
    '请上传证件反面': 'Please Upload ID Card Back',
    '上传成功，等待审核': 'Upload Successful, Waiting for Review',
    '删除': 'Delete',
    '添加地址': 'Add Address',
    '提示': 'Tip',
    '确定要删除该银行卡': 'Are you sure you want to delete this bank card?',
    '删除成功': 'Delete Successful',
    '银行': 'Bank',
    '请输入银行名称': 'Please Enter Bank Name',
    '开户行': 'Opening Bank',
    '请输入开户行': 'Please enter the bank branch',
    '卡号': 'Card Number',
    '请输入卡号': 'Please enter the card number',
    '持卡人': 'Cardholder',
    '请输入持卡人名称': 'Please enter the cardholder name',
    '保存': 'Save',
    '绑定银行卡': 'Bind Bank Card',
    '绑定成功': 'Binding Successful',
    '修改银行卡': 'Modify Bank Card',
    '推广': 'Promotion',
    '团队规模': 'Team Size',
    '我的收入': 'My Income',
    '推荐码': 'Referral Code',
    '复制': 'Copy',
    '邀请链接': 'Invitation Link',
    '一级分佣': 'First Level Commission',
    '返佣比例': 'Commission Ratio',
    '二级分佣': 'Second Level Commission',
    '三级分佣': 'Third Level Commission',
    '规则': 'Rules',
    '只有充值成功成为会员的人才有资格邀请朋友加入': 'Only those who have successfully become members by recharging are eligible to invite friends to join',
    '并且需要朋友充值激活VIP会员以后才可以获得邀请奖金': 'And friends need to recharge and activate VIP membership to receive invitation bonuses',
    '邀请奖金': 'Invitation Bonus',
    '激活立即获得': 'Activate Immediately to Receive',
    '升级没有奖金，只有首次激活的用户才可以获得这个邀请奖金的被动收入': 'There is no bonus for upgrading, only users who are first activated can receive the passive income of this invitation bonus',
    '复制成功': 'Copy Successful',
    '复制失败': 'Copy Failed',
    '修改昵称': 'Modify Nickname',
    '更改登入密码': 'Change Login Password',
    '设置资金密码': 'Set Fund Password',
    'USDT地址': 'USDT Address',
    'U类型': 'U Type',
    '请选择U类型': 'Please select U type',
    '请输入地址': 'Please enter address',
    '原密码': 'Original Password',
    '请输入原密码': 'Please enter the original password',
    '新密码': 'New Password',
    '请输入新密码': 'Please enter the new password',
    '提款密码': 'Withdrawal Password',
    '请输入提款密码': 'Please enter the withdrawal password',
    '用户昵称': 'User Nickname',
    '请输入昵称': 'Please enter a nickname',
    '全部': 'All',
    '已提交': 'Submitted',
    '审核中': 'Under Review',
    '处理中': 'Processing',
    '成功': 'Success',
    '充值金额': 'Recharge Amount',
    '充值凭证': 'Recharge Voucher',
    '请输入充值金额': 'Please enter the recharge amount',
    '提交成功，后台审核中': 'Submitted successfully, under backend review',
    '充值记录': 'Recharge Records',
    '提现记录': 'Withdrawal Records',
    '待审核': 'Pending Review',
    '已通过': 'Approved',
    '已取消': 'Cancelled',
    '创作会员': 'Creation Member',
    '剪辑会员': 'Editing Member',
    '账号资金': 'Account Funds',
    '账号余额': 'Account Balance',
    '线下充值': 'Offline Recharge',
    '线上充值': 'Online Recharge',
    '佣金': 'Commission',
    '升级剪辑': 'Upgrade Editing',
    '没有更多了': 'No more',
    '总资产': 'Total Assets',
    '去充值': 'Go to Recharge',
    '选择银行卡卡号': 'Select Bank Card Number',
    '提现金额': 'Withdrawal Amount',
    '提现密码': 'Withdrawal Password',
    '请输入提现金额': 'Please enter the withdrawal amount',
    '请输入提现密码': 'Please enter the withdrawal password',
    '请先绑定银行卡': 'Please bind your bank card first',
    '请选择银行卡': 'Please select a bank card',
    '提现成功': 'Withdrawal Successful',
    '未设置提款密码': 'Withdrawal password not set',
    '创作等级': 'Creation Level',
    '剪辑等级': 'Editing Level',
    '新手教程': 'Beginner Tutorial',
    '职位晋升': 'Position Promotion',
    '公司简介': 'Company Introduction',
    '合法执照': 'Legal License',
    'App下载': 'App Download',
    '作品集': 'Portfolio',
    '未通过': 'Not Passed',
    '暂无更多作品': 'No more works for now',
    '确定要删除该作品': 'Are you sure you want to delete this work?',
    '普通用户无访问权限，请开通会员后尝试': 'Regular users do not have access permission, please try after becoming a member',
    '姓名': 'Name',
    '请输入姓名': 'Please enter your name',
    '性别': 'Gender',
    '请输入性别': 'Please enter your gender',
    '年龄': 'Age',
    '请输入年龄': 'Please enter your age',
    '身份证编码': 'ID Card Number',
    '请输入身份证编码': 'Please enter your ID card number',
    '营业执照': 'Business License',
    '确认': 'Confirm',
    '取消': 'Cancel',
    '男': 'Male',
    '女': 'Female',
    '请上传营业执照': 'Please upload your business license',
    '切换语言': 'Switch Language',
    '切换成功': 'Switch Successful',
}
export default en;
