<template>
  <router-view/>
</template>
<script>
// import { onMounted, getCurrentInstance } from "vue";
// import { transformParams } from "@/utils/tool.js";
export default {
  setup() {
    // let { proxy } = getCurrentInstance()
    // onMounted(() => {
    //   const { language } = transformParams();
    //   localStorage.setItem("language", language ?? "en");
    //   // 关键之处在这里
    //   proxy.$i18n.locale = language; // 切换当前语言环境
    // })
  }
}
</script>

<style lang="scss">
body {
  background-color: #f2f2f2;
  font-size: 16px;
}
p {
  margin: 0;
}
</style>
